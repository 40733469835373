import React, { Suspense, useState } from "react";
import "./Trailers.scss";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper";
import "swiper/css/navigation";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { useEffect } from "react";

const Card = React.lazy(() => import("./card/Card"));

export default function Trailers({ id }) {
	const [swipers, setSwiper] = useState(null);
	const [slidesPerViews, setSlidesPerViews] = useState(3);
	const state = useSelector((state) => state.trailers.all_trailers);
	const title = useSelector((state) => state.main.main_page);
	let width = window.screen.width;
	useEffect(() => {
		if (width <= 768 && width > 575) {
			setSlidesPerViews(2) 	;
			return;
		}
		if (width <= 575) {
			setSlidesPerViews(1);
			return;
		}
	}, [width]);
	return (
		<section className="trailers" id={id}>
			<div className="trailers__container">
				<p className="trailers__title title-h2">{title?.trailer_block_title}</p>
				<div className="trailers__row">
					<Suspense fallback={<div>Loading...</div>}>
						<Swiper
							modules={[Navigation]}
							slidesPerView={slidesPerViews}
							onSwiper={(swiper) => {
								setSwiper(swiper);
							}}
							spaceBetween={30}
							className="">
							{state?.map((trailer, index) => (
								<SwiperSlide key={index}>
									<Card key={index} url={`/${trailer["slug"]}`} info={trailer} />
								</SwiperSlide>
							))}
						</Swiper>
					</Suspense>
				</div>
				<div className="trailers__buttons-row">
					<button
						aria-label="arrow left"
						className="reviews__button reviews__button_left "
						onClick={() => {
							swipers.slidePrev();
						}}></button>
					<button
						aria-label="arrow right"
						className="reviews__button reviews__button_right "
						onClick={() => {
							swipers.slideNext();
						}}></button>
				</div>
			</div>
		</section>
	);
}
