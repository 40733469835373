import { useEffect } from "react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import Header from "../../../components/header/Header";
import Description from "../../../components/description/Description";
import Footer from "../../../components/footer/Footer";
import ButtonGreen from "../../../components/button/green/ButtonGreen";
import { footer } from "../footer";
import { setLocationPage } from "../../../store/location-page/actions";

import {
	setLanguageEn,
	setLanguageHe,
	setLanguageRu,
} from "../../../store/language/actions";
import {
	configLangEn,
	configLangHe,
	configLangRu,
} from "../../../helper/change-language";
import "./LocationPage.scss";
import DOMParserReact from "dom-parser-react";
import Aside from "../../../components/aside/Aside";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HelmetConfig from "../../../components/helmet/HelmetConfig";
import { chartLimitation } from "../../../helper/characterLimitation";
import ReactGA from "react-ga";

export default function LocationPageRu() {
	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);
	const params = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const language = useSelector((state) => state.language.language);
	const lang_params = useSelector((state) => state.language.params);
	const location = useSelector((state) => state.location_page.current_location);
	const main_info = useSelector((state) => state.main.default_info.camping_page);
	useEffect(() => {
		if (params.lang === "he") {
			localStorage.setItem("isChange", "he");
			configLangHe();
			dispatch(setLanguageHe());
			return;
		}
		if (params.lang === "ru") {
			localStorage.setItem("isChange", "ru");
			configLangRu();
			dispatch(setLanguageRu());
			return;
		}
		if (params.lang === "en") {
			configLangEn();
			dispatch(setLanguageEn());
			return;
		}
	}, []);

	useEffect(() => {
		if (params.lang === "he") {
			localStorage.setItem("isChange", "he");
			configLangHe();
			dispatch(setLanguageHe());
			return;
		}
		if (params.lang === "ru") {
			localStorage.setItem("isChange", "ru");
			configLangRu();
			dispatch(setLanguageRu());
			return;
		}
		if (params.lang === "en") {
			configLangEn();
			dispatch(setLanguageEn());
			return;
		}
	}, [dispatch, language.headers, navigate, params.lang, params.slug]);

	useEffect(() => {
		dispatch(setLocationPage(params.slug, lang_params));
	}, [dispatch, lang_params, params.slug]);

	return (
		<div className="page location-page ">
			<HelmetConfig
				title={chartLimitation(location?.name)}
				desc={chartLimitation(location?.short_description)}
			/>
			<Header image={main_info?.top_image} />
			<Description desc={""} url="/" title={main_info?.top_title}></Description>
			<div className="location-page__container">
				<div className="location-page__wrapper">
					<div className="location-page__breadcrumbs">
						<Breadcrumbs
							className="location-page__breadcrumbs-row"
							aria-label="breadcrumb"
							separator="—">
							<Link
								className="location-page__breadcrumbs"
								underline="hover"
								color="inherit"
								to="/">
								{language.news.breadcrumbs_a}
							</Link>

							<Link
								className="location-page__breadcrumbs"
								underline="hover"
								color="inherit"
								to={`/news/all/${localStorage.getItem("isChange")}/`}>
								{language.news.breadcrumbs_b}
							</Link>
							<Typography className="location-page__breadcrumbs">
								{" "}
								{location?.name}
							</Typography>
						</Breadcrumbs>
					</div>
					<div className="location-page__row">
						<div className="location-page__aside">
							<Aside />
						</div>
						<div className="location-page__main">
							<main className="main-location">
								<div className="main-location__wrapper">
									<p className="main-location__titile">{location?.name}</p>

									<p className="main-location__date">
										{location?.created_at?.substring(0, 10)}
									</p>
									<p className="main-location__desc">
										<DOMParserReact source={location?.short_description} />
									</p>

									{location?.images?.length !== 0 && (
										<div className="main-location__swiper">
											<Swiper
												pagination={{
													type: "progressbar",
												}}
												navigation={true}
												modules={[Pagination, Navigation]}
												className="swiper-location ">
												{location?.images?.map((item, i) => (
													<SwiperSlide key={i}>
														<div
															className="swiper-location__slide"
															style={{
																background: `center/cover no-repeat url(${item.image})`,
															}}></div>
													</SwiperSlide>
												))}
											</Swiper>
										</div>
									)}
								
										<ButtonGreen
											className="location-page__button"
											title={language?.button_more}
											url={"https://app.b-on.co.il/online/order-v2/dream-caravans"}
										/>
									{/* </Link> */}
								</div>
								<p className="location-page__main-desc">
									<DOMParserReact source={location?.description} />
								</p>
							</main>
						</div>
					</div>
				</div>
			</div>

			<Footer footer={footer} />
		</div>
	);
}
