
export const SET_LOADER_ON = 'loader/SET_LOADER_ON';
export const SET_LOADER_OFF = 'loader/SET_LOADER_OFF';


export function setLoaderOff() {
  return {type: SET_LOADER_OFF}
}
export function setLoaderOn() {
  return {type: SET_LOADER_ON}
}
