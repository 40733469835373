import React from "react";
import "./NewsPage.scss";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../components/header/Header";
import Description from "../../../components/description/Description";
import Footer from "../../../components/footer/Footer";
import { footer } from "../footer";
import { useEffect } from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import LoactionCard from "../../../components/loactionCard/LoactionCard";
import { setCategories } from "../../../store/categories/actions";
import ReactGA from "react-ga";

export default function NewsPage() {
	const dispatch = useDispatch();

	useEffect(()=>{
		ReactGA.pageview(window.location.pathname);
	},[])
	const category = useSelector((state) => state.category.category);
	const language = useSelector((state) => state?.language?.language);
	const language_params = useSelector((state) => state.language.params);
	const location = useSelector((state) => state.location);
	const main_img = useSelector((state) => state.main.main_page);
	useEffect(() => {
		dispatch(setCategories(language_params));
	}, [dispatch, language_params]);
	
	const activeClass = (e) => {
		document.querySelectorAll(".news-page__button").forEach((i) => {
			i.classList.remove("active");
		});
		e.target.classList.add("active");

		let sort = document.querySelectorAll(".location-card");

		sort.forEach((i) => {
			const name = i
				.querySelector(".location-card__name")
				.textContent.toLowerCase();
			const target_name = e.target.textContent.toLowerCase();
			if (e.target.getAttribute("data-all")) {
				i.classList.remove("hide");
				return;
			}
			if (name !== target_name) {
				i.classList.add("hide");
				return;
			} else {
				i.classList.remove("hide");
			}
		});
	};

	return (
		<div className="page location-page  news-page">
			
			<Header image={main_img?.news_image} />
			<Description
				desc={location?.description}
				url="/"
				title={language.blog_title}></Description>
			<div className="page__container">
				<div className="page__wrapper">
					<div className="news-page__wrapper">
						<div className="location-page__breadcrumbs">
							<Breadcrumbs
								className="location-page__breadcrumbs-row"
								aria-label="breadcrumb"
								separator="—">
								<Link
									className="location-page__breadcrumbs"
									underline="hover"
									color="inherit"
									to="/">
									{language.news.breadcrumbs_a}
								</Link>

								<Typography className="location-page__breadcrumbs">
									{" "}
									{language.news.breadcrumbs_b}
								</Typography>
							</Breadcrumbs>
						</div>
						<div className="news-page__category">
							<button
								onClick={activeClass}
								className="news-page__button active"
								data-all="all">
								{language.news.all}
							</button>
							{category &&
								category?.map((item, key) => (
									<button onClick={activeClass} key={key} className="news-page__button">
										{item.name}
									</button>
								))}
						</div>

						<div className="news-page__cards">
							{location?.locations.length &&
								location?.locations?.map((item, i) => (
									<LoactionCard key={i} location={item} />
								))}
						</div>
					</div>
				</div>
			</div>
			<Footer footer={footer} />
		</div>
	);
}
