import cloneDeep from "lodash.clonedeep";
import {GET_STATUS} from "./actions";

const initialState = {
  status: null,
  data: {
    address: "user@example.com",
    subject: "string",
    body: "string",
    site: "0817feea-70d2-4f97-bab8-78b80e04cd6e"
  }
  // currentTrauler:{}
}


export const mailReduser = (state = initialState, action) => {

  switch (action.type) {
    case GET_STATUS:
      {
        const clone = cloneDeep(state);

        clone.status = action.data.status;

        return clone;
      }

    default:
      return state
  }
}
