import React from "react";
import { useEffect } from "react";
import Header from "../../../components/header/Header";

import { useDispatch, useSelector } from "react-redux";
import { setAgreement } from "../../../store/agreement/actions";
import { useParams } from "react-router";
import {
	configLangEn,
	configLangHe,
	configLangRu,
} from "../../../helper/change-language";
import {
	setLanguageEn,
	setLanguageHe,
	setLanguageRu,
} from "../../../store/language/actions";
import Description from "../../../components/description/Description";
import Footer from "../../../components/footer/Footer";
import DOMParserReact from "dom-parser-react";
import HelmetConfig from "../../../components/helmet/HelmetConfig";
import { chartLimitation } from "../../../helper/characterLimitation";
import ReactGA from "react-ga";
import "./Agreement.scss";

export default function Agreement() {
	const dispatch = useDispatch();
	const params = useParams();

	const language_params = useSelector((state) => state.language.params);
	const agreement = useSelector((state) => state.agreement_page.agreement);

	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);

	useEffect(() => {
		if (params.lang === "he") {
			localStorage.setItem("isChange", "he");
			configLangHe();
			dispatch(setLanguageHe());
			return;
		}
		if (params.lang === "ru") {
			localStorage.setItem("isChange", "ru");
			configLangRu();
			dispatch(setLanguageRu());
			return;
		}
		if (params.lang === "en") {
			configLangEn();
			dispatch(setLanguageEn());
			return;
		}
	}, [dispatch, params]);

	useEffect(() => {
		dispatch(setAgreement(language_params));
	}, [dispatch, language_params]);

	return (
		<div className="page trailer-page agreement">
			<HelmetConfig
				title={chartLimitation(agreement?.title)}
				desc={chartLimitation(agreement?.description)}
			/>
			<Header image={agreement.image} />
			<Description
				desc={agreement?.description}
				url="/"
				title={agreement?.title}></Description>
			<div className="page__container">
				<div className="page__wrapper">
					<div className="agreement__container">
						{agreement?.clause?.map((item, index) => (
							<div className="agreement__box" key={index}>
								<p className="agreement__title">
									<DOMParserReact source={item.text} />{" "}
								</p>
								{item.subclass.map((p, i) => (
									<p className="agreement__desc" key={i}>
										<DOMParserReact source={p.text} />
									</p>
								))}
							</div>
						))}
					</div>
				</div>
			</div>
			<Footer></Footer>
		</div>
	);
}
