import cloneDeep from "lodash.clonedeep";
import {GET_DISABLED_IMAGE, GET_DISABLED_FONTS, GET_DISABLED_BACKGROUND} from "./action";

const initialState = {
  disabled_config: JSON.parse(localStorage.getItem('disabled-config')) || {
    image: null,
    bg: null,
    fonts: null
  }
}

export const disabledConfigReduser = (state = initialState, action) => {

  switch (action.type) {

    case GET_DISABLED_IMAGE:
      {
        const clone = cloneDeep(state);
        clone.disabled_config.image = action.data;
        // console.log(action.data)
        return clone;
      }
    case GET_DISABLED_FONTS:
      {
        const clone = cloneDeep(state);
        clone.disabled_config.fonts = action.data;
        // console.log(action.data)
        return clone;
      }
    case GET_DISABLED_BACKGROUND:
      {
        const clone = cloneDeep(state);
        clone.disabled_config.bg = action.data;
        return clone;
      }
    default:
      return state
  }
}
