import { getAllCategories} from "../../API/APIService";

export const SET_CATEGORIES = 'agreement/SET_AGREEMENT_PAGE';

export function setCategories(params) {
  return async dispatch => {
    try {
      const response = await getAllCategories(params);
      const data = await response.data;
      dispatch({type: SET_CATEGORIES, data: data})

    } catch (e) {}
  }
}
