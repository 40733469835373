import React from "react";
import "./ButtonTransparent.scss";

export default function ButtonTransparent({ className, url, title , onClick}) {
	return (
		<a className={`button-transparent ${className}`} 	onClick={onClick} href={url}>
			{title}
		</a>
	);
}
