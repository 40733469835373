import cloneDeep from "lodash.clonedeep";
import {SET_LOCATION_PAGE} from "./actions";

const initialState = {
  current_location: {}

}

export const LocationPageReduser = (state = initialState, action) => {

  switch (action.type) {

    case SET_LOCATION_PAGE:
      const clone = cloneDeep(state);
      clone.current_location = action.data;
      return clone;

    default:
      return state
  }
}
