import cloneDeep from "lodash.clonedeep";
import {SET_LOADER_ON, SET_LOADER_OFF} from "./actions";

const initialState = {
  loader: false

}

export const loaderReduser = (state = initialState, action) => {

  switch (action.type) {

    case SET_LOADER_ON:
      {
        const clone = cloneDeep(state);
        clone.loader = true;
        return clone;
      } case SET_LOADER_OFF:
      {
        const clone = cloneDeep(state);
        clone.loader = false;
        return clone;
      }


    default:
      return state
  }
}
