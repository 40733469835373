import cloneDeep from "lodash.clonedeep";
import {SET_TRAILERS} from "./actions";

const initialState = {
  all_trailers: []

}

export const TrailersReduser = (state = initialState, action) => {

  switch (action.type) {

    case SET_TRAILERS:
      const trailers = action.data.map(item => item)
      const clone = cloneDeep(state);
      clone.all_trailers = trailers;
      return clone;

    default:
      return state
  }
}
