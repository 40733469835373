import cloneDeep from "lodash.clonedeep";
import {SET_MAIN_PAGE} from "./actions";

const initialState = {
  default_info: {},
  main_page: {},
  camping_page: {},
  trailer_page: {},
  header: {},
  footer: {},
  connect: {},
}

export const mainInfoReduser = (state = initialState, action) => {

  switch (action.type) {
 
    case SET_MAIN_PAGE:
      const {
        main_page,
        header,
        footer,
        connect_block,
        trailer_page,
        camping_page
      } = action.data;
      const clone = cloneDeep(state);
      clone.default_info = action.data;
      clone.main_page = main_page;
      clone.camping_page = camping_page;

      clone.header = header;
      clone.footer = footer;
      clone.connect = connect_block;
      clone.trailer_page = trailer_page;
      return clone;

    default:
      return state
  }
}
