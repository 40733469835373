import React from "react";
import { useSelector } from "react-redux";
import DOMParserReact from "dom-parser-react";
import "./About.scss";

export default function About() {
	const state = useSelector((state) => state.main.main_page);

	return (
		<section className="about" id="about">
			<div className="about__container">
				{state?.about_block_title && (
					<h2 className="about__title title-h2">{state?.about_block_title}</h2>
				)}
				<p className="about__desc desc ">
					{state?.about_block_text && (
						<DOMParserReact source={state?.about_block_text} />
					)}
				</p>
			</div>
		</section>
	);
}
