import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./Gallery.scss";

export default function Gallery({ title, images }) {
	const [thumbsSwiper, setThumbsSwiper] = useState(null);

	return (
		<div className="gallery">
			<div className="gallery__container">
				<p className="gallery__title title-h2">{title}</p>
				<div className="gallery__thumb">
					<Swiper
						spaceBetween={16}
						navigation={true}
						thumbs={{
							swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
						}}
						modules={[FreeMode, Navigation, Thumbs]}
						className="gallery-thumbs">
						{images?.length &&
							images.map((item, index) => (
								<SwiperSlide key={index}>
									<div
										className={`gallery-thumbs__item  gallery-thumbs__item_${index}`}
										style={{
											backgroundImage: `url(${item.image})`,
										}}></div>
								</SwiperSlide>
							))}
					</Swiper>
					<Swiper
						onSwiper={setThumbsSwiper}
						spaceBetween={10}
						slidesPerView={4}
						freeMode={true}
						watchSlidesProgress={true}
						modules={[FreeMode, Navigation, Thumbs]}
						className="gallery-thumbs-small">
						{images?.length &&
							images.map((item, index) => (
								<SwiperSlide key={index}>
									<div
										className={`gallery-thumbs-small__item  gallery-thumbs-small__item_${index}`}
										style={{
											backgroundImage: `url(${item.image})`,
										}}></div>
									{/* ) : ( */}
									{/* <>
											<video
												width="750"
												height="500"
												no-controls
												className={`gallery-thumbs-small__item  gallery-thumbs-small__item_${index}`}>
												<source src={item.image} type="video/mp4" />
											</video>
										</>
									)} */}
								</SwiperSlide>
							))}
					</Swiper>
				</div>
			</div>
		</div>
	);
}
