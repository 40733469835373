import React from "react";
import { useDispatch } from "react-redux";
import {
	setLanguageEn,
	setLanguageHe,
	setLanguageRu,
} from "../../store/language/actions";
import "./Language.scss";
import { useNavigate, useParams } from "react-router";
import {
	configLangEn,
	configLangHe,
	configLangRu,
} from "../../helper/change-language";

export default function Language() {

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const params = useParams();

	const changeLang = (e) => {
		let lang_attr = e.target;

		let current_lang = document.querySelector(".languages__link_active");
		current_lang.setAttribute("data-lang", lang_attr.getAttribute("data-lang"));
		current_lang.textContent = e.target.textContent;

		if (e.target.getAttribute("data-lang") === "he") {
			localStorage.setItem("isChange", "he");
			configLangHe();
			dispatch(setLanguageHe());

			document.querySelector(".language__lists").classList.toggle("active");
			if (!params.slug) {
				navigate("/he");
			}
			if (params.trailers) {
				navigate(`/trailers/trailer-card/he/${params.slug}`);
				return;
			}

			if (params.location === "location-card") {
				navigate(`/locations/location-card/he/${params.slug}`);
			}
			if (params.docs === "docs") {
				navigate(`/documents/docs/he/`);
			}
			if (params.article) {
				navigate(`/agreement/article/he/`);
				return;
			}
			if (params.all) {
				navigate(`/news/all/he/`);
				return;
			}

			return;
		}
		if (e.target.getAttribute("data-lang") === "ru") {
			localStorage.setItem("isChange", "ru");
			configLangRu();
			dispatch(setLanguageRu());
			document.querySelector(".language__lists").classList.toggle("active");
			if (!params.slug) {
				navigate("/ru");
			}
			if (params.trailers) {
				navigate(`/trailers/trailer-card/ru/${params.slug}`);
				return;
			}
			if (params.location === "location-card") {
				navigate(`/locations/location-card/ru/${params.slug}`);
			}
			if (params.docs === "docs") {
				navigate(`/documents/docs/ru/`);
			}
			if (params.article) {
				navigate(`/agreement/article/ru/`);
				return;
			}
			if (params.all) {
				navigate(`/news/all/ru/`);
				return;
			}
			return;
		}
		if (e.target.getAttribute("data-lang") === "en") {
			localStorage.setItem("isChange", "en");
			configLangEn();
			dispatch(setLanguageEn());
			document.querySelector(".language__lists").classList.toggle("active");

			if (!params.slug) {
				navigate("/en");
			}

			if (params.trailers) {
				navigate(`/trailers/trailer-card/en/${params.slug}`);
				return;
			}
			if (params.location === "location-card") {
				navigate(`/locations/location-card/en/${params.slug}`);
			}
			if (params.docs === "docs") {
				navigate(`/documents/docs/en/`);
			}
			if (params.article) {
				navigate(`/agreement/article/en/`);
				return;
			}
			if (params.all) {
				navigate(`/news/all/en/`);
				return;
			}
			return;
		}
	};
	return (
		<div className="languages">
			<ul className="languages__list">
				<>
					<li className="languages__item">
						<button
							onClick={changeLang}
							className="languages__link languages__link_ru  languages__link_active"
							data-lang="ru">
							Ru
						</button>
					</li>
					<li className="language__lists">
						<ul>
							<li className="languages__item languages__box active ru">
								<button
									onClick={changeLang}
									className="languages__link  languages__link_ru languages__link_default "
									data-lang="ru">
									Ru
								</button>
							</li>
							<li className="languages__item languages__box active he">
								<button
									onClick={changeLang}
									className="languages__link  languages__link_he languages__link_default "
									data-lang="he">
									He
								</button>
							</li>
							<li className="languages__item languages__box active en">
								<button
									onClick={changeLang}
									className="languages__link  languages__link_en"
									data-lang="en">
									En
								</button>
							</li>
						</ul>
					</li>
				</>
			</ul>
		</div>
	);
}

