import {getLocationPage} from "../../API/APIService";
import {setLoaderOff, setLoaderOn} from "../loader/actions";

export const SET_LOCATION_PAGE = 'trailers-page/SET_LOCATION_PAGE';

export function setLocationPage(slug, params) {
  return async dispatch => {
    try {
      dispatch(setLoaderOn())
      const response = await getLocationPage(slug, params);
      const data = await response.data;
      dispatch({type: SET_LOCATION_PAGE, data: data})
      dispatch(setLoaderOff())

    } catch (e) {}
  }
}
