import React from "react";
import { useSelector } from "react-redux";
import ButtonGreen from "../button/green/ButtonGreen";
import DOMParserReact from "dom-parser-react";
import ReactGA from "react-ga";
import "./Contacts.scss";

export default function Contacts({ id }) {
	const state = useSelector((state) => state?.main?.connect);
	const default_info = useSelector(
		(state) => state?.main.default_info?.phone_number
	);
	const language = useSelector((state) => state?.language?.language);

	return (
		<section className="contact" id={id}>
			<div className="contact__container">
				<div className="contact__box">
					<div className="contact__content">
						<div className="contact__info">
							<p className="contact__title title-h2">{state?.title}</p>
							<p className="contact__desc">
								<DOMParserReact source={state?.text} />
							</p>
						</div>
					</div>
					<div className="contact__phone">
						<div className="contact__phone-box">
							<a 
								href={`tel:${default_info}`}
								className="contact__link"
								onClick={() => {
									ReactGA.event({
										category: "button",
										action: "click",
										label: "phone ",
										nonInteraction: false,
									});
								}}>
								{default_info}
							</a>
							<ButtonGreen
								title={language.button_more}
								url={"https://app.b-on.co.il/online/order-v2/dream-caravans"}
								className="contact__button"
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
