export const params_ru = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'ru'
  }
}
export const params_he = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'he'
  }
}
export const params_en = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'en'
  }
}

