export const infoRu = {
  // buttons
  button_more: 'заказать сейчас',
  button_show: 'Посмотреть',
  button_consultation: 'получить консультацию',
  button_contact: 'Заказать',
  news:{
    breadcrumbs_a:'Главная',
    breadcrumbs_b:'Блог',
    all:"Все"

  },
  aside:{
    social_title:"Будьте в курсе наших акций и новостей",
    social_desc:"подписаться",
    hit:"Хит",
    hit_desc:'Современный вместительный трейлер',
    blog:"статьи",
    
  },
  // titles
  blog_title:"Блог",
  contact_title: 'контакты',
  // units
  price:'Цена',
  height: 'Высота',
  height_units: 'м',
  length: 'Длина',
  length_units: 'м',
  weight: 'Вес',
  // square: 'Полезная площадь',
  square: 'Полезная площадь',
  square_units: 'м',
// 
  weight_units: 'т',
  sleeping_places: 'Спальные места',

  // popup
  popup_title:"Зарегестрируйтесь и получите консультацию по интересующему вопросу",
  popup_name:"Your name",
  popup_email:"Email",
  popup_phone:"Phone",

  title_location:'Куда можно отправиться на трейлере?', 

  disabled: {
    font:"Pазмер шрифта Px",
    bg:"Цвет сайта",
    img:"ИЗОБРАЖЕНИЯ",
    reset:'сбросить'
  },

  news_bnt:"Все новости",
  cookies: {
    text: `	Мы используем файлы cookie, чтобы обеспечить вам максимальное удобство на
    нашем сайте.`,
    button: "OK"
  }
}


