import {getLocations} from "../../API/APIService";

export const SET_LOCATIONS = 'locations /SET_LOCATIONS';

export function setLocations(params) {
  return async dispatch => {
    try {
      const response = await getLocations(params);
      const data = await response.data;
      dispatch({type: SET_LOCATIONS, data: data})

    } catch (e) {}
  }
}
