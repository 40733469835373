	import React, { startTransition } from "react";
	import { Link } from "react-scroll";
	import { Link as RouteLink, useParams } from "react-router-dom";
	import { useSelector } from "react-redux";
	import Language from "../languages/Language";
	import { useEffect, useState } from "react";
	import "./Header.scss";
	import "swiper/css";
	import "swiper/css/navigation";

	const Additional = React.lazy(() => import("./adittional/Additional"));
	const DisabledMenu = React.lazy(() =>
		import("../../disabled-version/DisabledMenu")
	);
	const Social = React.lazy(() => import("./social/Social"));
	const MobileMenu = React.lazy(() => import("../mobileMenu/MobileMenu"));

	// SwiperCore.use([Pagination]);

	export default function Header({ image }) {
	// const [mobileStatus, setMobileStatus] = useState(false)

		const [isMain, setIsMain] = useState(true);
		const [isMainLink, setIsMainLink] = useState(false);

		const [isMobile, setisMobile] = useState(false);
		const params = useParams();

		const header = useSelector((state) => state.main.header);
		const defaultInfo = useSelector((state) => state.main.default_info);

		const screenWidth = window.screen.width;
		useEffect(() => {
			if (screenWidth <= 768) {
				setisMobile(true);
			}
		}, [screenWidth]);

		useEffect(() => {
			if (params.slug || params.article || params.docs) {
				setIsMain(false);
			}
		}, [params]);

		const openModal = (e) => {
		
			document.querySelector(".mobile-menu").classList.add("active");
			document.querySelector("html").classList.add("overflow-hidden");
		};
		const closeModal = (e) => {
			document.querySelector(".mobile-menu").classList.remove("active");
			document.querySelector("html").classList.remove("overflow-hidden");
		};

		const handleDisabledIcon = (e) => {
			document.querySelector(".disabled-menu").classList.toggle("active");
		};

		
		const handleClickMainLink = (e) => {
			startTransition(() => {
				e.preventDefault();
				setIsMainLink(!isMainLink);
				e.target.classList.toggle("_close");
			});
			
		};

		const default_info = useSelector((state) => state.main.default_info.main_page);

		return (
			<header className="header">
				{image && (
					<div
						className="header__bg"
						style={{ backgroundImage: ` url(${image})` }}></div>
				)}

				<>
					<div className="header__container">
						<div className="header__inner ">
							<div className="header__wrapper">
								<div className="header__box">
									<RouteLink
										to="/"
										className="header__logo "
										aria-label="logo"
										style={{
											background: `	left/contain no-repeat url(${defaultInfo?.logo})`,
										}}></RouteLink>
									<div className="header__list">
										{isMainLink && <Additional submenu_items={header?.submenu_items} />}
										{(isMain &&
											header?.menu_items?.length &&
											header?.menu_items?.map((link, index) => {
												if (link.url === "about-us-button") {
													return (
														<p
															className="header__link additional-menu"
															key={index}
															// activeClass="active"
															onClick={handleClickMainLink}>
															{link.name}
														</p>
													);
												} else {
													return (
														<Link className="header__link " key={index} to={link.url} href="#">
															{link.name}
														</Link>
													);
												}
											})) ||
											(header?.menu_items?.length &&
												header?.menu_items?.map((link, index) => {
													if (link.url === "about-us-button") {
														return (
															<p
																className="header__link additional-menu"
																key={index}
																// activeClass="active"
																onClick={handleClickMainLink}>
																{link.name}
															</p>
														);
													} else {
														return (
															<RouteLink
																key={index}
																className="header__link "
																href="#"
																to={"/"}>
																{link.name}
															</RouteLink>
														);
													}
												}))}
									</div>
								</div>
								<div className="header__items">
									<Social />
									<div className="header__languages">
										<a href={`tel:${defaultInfo?.phone_number}`} className="header__phone">
											{}
										</a>
										{!isMobile && <Language />}
									</div>
									<button
										type="button"
										aria-label="icon disabled"
										className="header__disabled-icon"
										onClick={handleDisabledIcon}></button>
								</div>
							</div>
						</div>
						<button
							type="button"
							aria-label="icon disabled"
							className="header__disabled-icon"
							onClick={handleDisabledIcon}></button>
						<div className="header__mobile">
							<RouteLink
								to="/"
								className="header__logo "
								aria-label="logo"
								style={{
									background: `	left/contain no-repeat url(${defaultInfo?.logo})`,
								}}></RouteLink>
							<button
								aria-label="burger"
								onClick={openModal}
								className="header__burger"></button>
						</div>
		{			 <MobileMenu onClick={closeModal} />}
						
					</div>
					<DisabledMenu />
					{default_info?.social_links?.length &&
						default_info?.social_links?.map(
							(item, index) =>
								item.name.toLowerCase() === "whatsapp" && (
									<a
										key={index}
										href={item.url}
										aria-label={item.name}
										className={`whatsapp-icon header-social__link header-social__link_${item.name.toLowerCase()}`}>
										{}
									</a>
								)
						)}
				</>

				{/* </CriticalStyles> */}
			</header>
		);
	}
