export const infoEn = {
  // buttons
  button_more: 'order now',
  button_show: 'Look',
  button_consultation: 'to get a consultation',
  button_contact: 'order',
  news:{
    breadcrumbs_a:'Main',
    breadcrumbs_b:'Blog',
    all:"All"

  },
  // titles
  aside:{
    social_title:"Stay informed of our promotions and news",
    social_desc:"subscribe",
    hit:"Hit",
    hit_desc:'Modern Roomy Trailer',
    blog:"article",
    
  },
  blog_title:"Blog",
  contact_title: 'contacts',
  // units
  price: 'price',
  height: 'height',
  height_units: 'm',
  length: 'length',
  length_units: 'l',
  weight: 'weight',
  weight_units: 't',
  sleeping_places: 'Sleeping places',
  square: "effective area",
  square_units: 'm',

  // popup
  popup_title: "Register and get advice on the issue of interest",
  popup_name: "Your name",
  popup_email: "Email",
  popup_phone: "Phone",

  title_location: 'Where can you go with a trailer?',
  disabled: {
    font: "Font size Px",
    bg: "background",
    img: "images",
    reset: "reset"
  },
  news_bnt:"all news",
  cookies: {
    text: `	We use cookies to provide you with maximum convenience on
    our site.`,
    button: "OK"
  }
}
