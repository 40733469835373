import cloneDeep from "lodash.clonedeep";
import {SET_TRAILER_PAGE} from "./actions";

const initialState = {
  current_trailer: {}

}

export const TrailersPageReduser = (state = initialState, action) => {

  switch (action.type) {

    case SET_TRAILER_PAGE:

      const clone = cloneDeep(state);
      clone.current_trailer = action.data;
      return clone;

    default:
      return state
  }
}
