import React from "react";
import { useSelector } from "react-redux";
import ButtonGreen from "../button/green/ButtonGreen";
import "./TrailerPlan.scss";
import DOMParserReact from "dom-parser-react";

export default function TrailerPlan({ trailer }) {
	const language = useSelector((state) => state.language.language);

	return (
		<div className="trailer-plan">
			<div className="trailer-plan__container">
				<div
					className="trailer-plan__img"
					style={{
						background: `center/contain no-repeat url(${trailer?.trailer_plan})`,
					}}></div>
				<div className="trailer-plan__info">
					<p className="trailer-plan__name">
						<DOMParserReact source={trailer?.name} />
					</p>
					<p className="trailer-plan__desc">
						{" "}
						<DOMParserReact source={trailer.short_description} />
					</p>
					<div className="trailer-plan__box">
						<p className="trailer-plan__text">
							<span>{language?.height}</span>
							<span>
								{trailer?.height}

								{language.height_units}
							</span>
						</p>
						<p className="trailer-plan__text">
							<span>{language?.length}</span>
							<span>
								{trailer?.width}
								{language?.length_units}
							</span>
						</p>
						<p className="trailer-plan__text">
							<span>{language?.weight}</span>
							<span>
								{trailer?.weight}
								{language?.weight_units}
							</span>
						</p>
						<p className="trailer-plan__text">
							<span>{language?.square}</span>
							<span>
								{trailer?.square}
								{language?.square_units}
							</span>
						</p>
						<p className="trailer-plan__text">
							<span>{language?.sleeping_places}</span>
							<span>{trailer?.sleeping_places}</span>
						</p>
						{/* <Link to="https://app.b-on.co.il/online/order-v2/dream-caravans"> */}
							<ButtonGreen
								title={language?.button_consultation}
								className="trailer-plan__button"
								url={"https://app.b-on.co.il/online/order-v2/dream-caravans"}
							/>
						{/* </Link> */}
					</div>
				</div>
			</div>
		</div>
	);
}
