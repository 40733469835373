import cloneDeep from "lodash.clonedeep";
import {SET_CATEGORIES} from "./actions";

const initialState = {
  category: []

}

export const categoryReduser = (state = initialState, action) => {

  switch (action.type) {

    case SET_CATEGORIES:
      const clone = cloneDeep(state);
      clone.category = action.data;
      return clone;

    default:
      return state
  }
}
