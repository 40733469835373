import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

// eslint-disable-next-line react/prop-types
export default function HelmetConfig({ title, desc }) {
	const defaultInfo = useSelector((state) => state.main.default_info);
	return (
		<Helmet>
			‍<title>{title}</title>‍
			<meta name="description" content={desc} />
			<link
				rel="icon"
				type="image/png"
				href={`${defaultInfo.favicon}`}
				sizes="16x16"
			/>
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={desc} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={desc} />
			<meta
				property="og:image"
				content="https://ltdfoto.ru/image/yJ3oqj"
				data-react-helmet="true"
			/>
			<meta
				property="twitter:image"
				content="https://ltdfoto.ru/image/yJ3oqj"
				data-react-helmet="true"
			/>
		</Helmet>
	);
}
