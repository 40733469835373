import {combineReducers} from "redux";
import {mailReduser} from "./email/reduser";
import {languageRedusers} from "./language/reduser";
import {loaderReduser} from "./loader/reduser";
import {LocationPageReduser} from "./location-page/reduser";
import {locationsReduser} from "./locations/reduser";
import {mainInfoReduser} from "./main-info/reduser";
import {sitesReduser} from "./sites/reduser";
import {TrailersPageReduser} from "./trailers-page/reduser";
import {TrailersReduser} from "./trailers/reduser";
import {disabledConfigReduser} from "./disabled-confing/reduser";
import {docsPageReduser} from "./docs/reduser";
import {agreementPageReduser} from "./agreement/reduser";
import { categoryReduser } from "./categories/reduser";

export default combineReducers({
  sites: sitesReduser,
  main: mainInfoReduser,
  trailers: TrailersReduser,
  trailers_page: TrailersPageReduser,
  location: locationsReduser,
  language: languageRedusers,
  location_page: LocationPageReduser,
  mail: mailReduser,
  docs_page: docsPageReduser,
  agreement_page: agreementPageReduser,
  loader: loaderReduser,
  disabled: disabledConfigReduser,
  category: categoryReduser
});
