import React from "react";
import DOMParserReact from "dom-parser-react";
import "./Title.scss";

export default function Title({ title }) {
	return (
		<p className="title title-h2">
			<DOMParserReact source={title} />{" "}
		</p>
	);
}
