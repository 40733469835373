import cloneDeep from "lodash.clonedeep";
import {SET_LOCATIONS} from "./actions";

const initialState = {
  locations:{}
}

export const locationsReduser = (state = initialState, action) => {

  switch (action.type) {

    case SET_LOCATIONS:
 
      const clone = cloneDeep(state);
      clone.locations = action.data;
      return clone;

    default:
      return state
  }
}
