export const footer = {
    desc: `Внезапно, диаграммы связей представляют собой не что иное,
     как квинтэссенцию победы маркетинга над разумом и должны быть призваны к ответу.`,
    titleTrailers: 'трейлеры',
    titleContacts: "контакты",
    phone: "+375 (44) 755-96-22",
    buttonUrl: '/location/name',
    buttonName: "получить консультацию"

}
