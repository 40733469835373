import React from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setTrailersPage } from "../../../store/trailers-page/actions";
import Header from "../../../components/header/Header";
import Description from "../../../components/description/Description";
import Gallery from "../../../components/gallery/Gallery";
import TrailerPlan from "../../../components/trailerPlan/TrailerPlan";
import Contacts from "../../../components/contacts/Contacts";
import Trailers from "../../../components/trailers/Trailers";
import Footer from "../../../components/footer/Footer";
import { footer } from "../footer";
import "./TrailerPage.scss";
import HelmetConfig from "../../../components/helmet/HelmetConfig";
import { chartLimitation } from "../../../helper/characterLimitation";
import ReactGA from "react-ga";

export default function TrailerPageRu() {
	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, [window.location.pathname]);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const params = useParams();

	const state = useSelector((state) => state.trailers_page.current_trailer);
	const main_info = useSelector((state) => state.main.trailer_page);
	const lang_params = useSelector((state) => state.language.params);
	const languages = useSelector((state) => state.language.language);

	useEffect(() => {
		dispatch(setTrailersPage(params.slug, lang_params));
	}, [lang_params, params.length, dispatch, params.slug]);

	useEffect(() => {
		if (params.lang === "he") {
			localStorage.setItem("isChange", "he");

			navigate(`/trailers/trailer-card/he/${params.slug}`);
			return;
		}
		if (params.lang === "ru") {
			localStorage.setItem("isChange", "ru");

			navigate(`/trailers/trailer-card/ru/${params.slug}`);
			return;
		}
		if (params.lang === "en") {
			localStorage.setItem("isChange", "en");
			navigate(`/trailers/trailer-card/en/${params.slug}`);
			return;
		}
	}, [dispatch, lang_params.headers, navigate, params.lang, params.slug]);

	return (
		<div className="page trailer-page ">
			<HelmetConfig
				title={chartLimitation(main_info?.top_title)}
				desc={chartLimitation(state?.description)}
			/>
			<Header image={state?.main_page_img} />
			<Description
				desc={state?.description}
				title={main_info?.top_title}
				buttonTitle={languages?.button_more}
			/>
			<div className="page__container">
				<div className="page__wrapper">
					{state?.images?.length !== 0 && (
						<Gallery images={state?.images} title={main_info?.image_block_title} />
					)}
					<TrailerPlan trailer={state} />
				</div>
			</div>
			<Contacts id="contacts" />
			<div className="page__container">
				<div className="page__wrapper">
					<Trailers id="trailers" />
				</div>
			</div>
			<Footer footer={footer} />
		</div>
	);
}
