import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./Aside.scss";

export default function Aside({ props }) {

	const server_info = useSelector((state) => state.main.default_info.main_page);
	const camping_page = useSelector((state) => state.main.camping_page);
	const trailers = useSelector((state) => state.trailers.all_trailers);
	const location = useSelector((state) => state?.location?.locations);
	const language = useSelector((state) => state?.language?.language);

	let hit_trailer = trailers?.sort(() => Math.random() - 0.5).slice(0, 1);

	return (
		<aside className="aside">
			<div className="aside__wrapper">
				<div className="aside__social">
					<div className="aside__row">
						{server_info?.social_links?.map((item, i) => (
							<a
								key={i}
								href={item?.url}
								className={`aside__link aside__link_${item.name.toLowerCase()}`}>
								{props}
							</a>
						))}
					</div>
					{/* <p className="aside__text">{language.aside.social_title}</p> */}
					{/* <p className="aside__subscribe">{language.aside.social_desc}</p> */}
				</div>
				{hit_trailer?.map((item, i) => (
					<div
						className="aside__trailer"
						key={i}
						style={{
							background: `center/cover no-repeat url(${item.main_image})`,
						}}>
						<p className="aside__trailer-hit">
						{camping_page?.news &&	<span>{camping_page?.news}</span>}
							{camping_page?.side_block_text}
						</p>
					</div>
				))}
				<div className="aside__atricle atricle">
					<p className="atricle__title">{language.aside.blog}</p>
					{location.length  && location?.slice(0,6).map((item, i) => (
						<Link
							key={i}
							className="atricle__box"
							to={`/locations/location-card/${localStorage.getItem("isChange")}/${
								item?.slug
							}`}>
							<div
								className="atricle__img"
								style={{
									background: `center/cover no-repeat url(	${item.main_image})`,
								}}>
			
							</div>
							<div className="atricle__desc">
								<p className="atricle__date">{item.created_at.substring(0, 10)}</p>
								<p className="atricle__text">{item.subname}</p>
							</div>
						</Link>
					))}
				</div>
			</div>
		</aside>
	);
}
