import cloneDeep from "lodash.clonedeep";
import {SET_AGREEMENT_PAGE} from "./actions";

const initialState = {
  agreement: {}

}

export const agreementPageReduser = (state = initialState, action) => {

  switch (action.type) {

    case SET_AGREEMENT_PAGE:
      const clone = cloneDeep(state);
      clone.agreement = action.data;
      return clone;

    default:
      return state
  }
}
