import React from "react";
import "./ButtonGreen.scss";

export default function ButtonGreen({ className, url, title, onClick }) {
	return (
		<a className={`button-green ${className}`} href={url} onClick={onClick} >
			{title}
		</a>
	);
}
