import React from 'react';
import {BrowserRouter} from "react-router-dom"
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux'
import ReactDOM from 'react-dom/client';
import store from './store/store'
import App from './App';
import './index.css';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render (<Provider store={store}>
  
  <BrowserRouter>
    {/* <Hemlet/> */}
    <App/>
  </BrowserRouter>
</Provider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
