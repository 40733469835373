import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import { footer } from "../footer";
import "./DocumentsPage.scss";
import { useNavigate, useParams } from "react-router";
import Description from "../../../components/description/Description";
import { useEffect } from "react";
import { setDocs } from "../../../store/docs/actions";
import {
	configLangEn,
	configLangHe,
	configLangRu,
} from "../../../helper/change-language";
import {
	setLanguageEn,
	setLanguageHe,
	setLanguageRu,
} from "../../../store/language/actions";
import HelmetConfig from "../../../components/helmet/HelmetConfig";
import { chartLimitation } from "../../../helper/characterLimitation";
import ReactGA from "react-ga";

export default function DocumentsPage() {
	useEffect(()=>{
		ReactGA.pageview(window.location.pathname);
	},[])
	const params = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const lang_params = useSelector((state) => state.language.params);
	const docs = useSelector((state) => state.docs_page.docs);
	useEffect(() => {
		dispatch(setDocs(lang_params));
	}, [dispatch, lang_params]);

	const language = useSelector((state) => state.language.language);

	useEffect(() => {
		if (params.lang === "he") {
			localStorage.setItem("isChange", "he");
			configLangHe();
			dispatch(setLanguageHe());
			return;
		}
		if (params.lang === "ru") {
			localStorage.setItem("isChange", "ru");
			configLangRu();
			dispatch(setLanguageRu());
			return;
		}
		if (params.lang === "en") {
			configLangEn();
			dispatch(setLanguageEn());
			return;
		}
	}, [dispatch, language.headers, navigate, params]);

	return (
		<div className="page docs trailer-page">
				<HelmetConfig
				title={docs?.title}
				desc={chartLimitation(docs?.description)}
			/>
			<Header image={docs.image} />
			<Description
				desc={docs?.description}
				url="/"
				title={docs?.title}></Description>
			<div className="page__container">
				<div className="page__wrapper">
					<div className="docs__wrapper">
						{docs?.docs?.map((doc, index) => (
							<div className="docs__box" key={index}>
								<p className="docs__title">{doc.title}</p>
								<a
									className="docs__download"
									href={doc?.file}
									download={doc?.file}
									target="_blank"
									rel="noreferrer">
									<span className="docs__icon docs__icon_pdf"></span>
									<span className="docs__icon docs__icon_download"></span>
								</a>
							</div>
						))}
					</div>
				</div>
			</div>
			<Footer footer={footer} />
		</div>
	);
}
