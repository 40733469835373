

import {Routes, Route, useParams} from "react-router-dom";
import PageMainRu from './pages/ru/main/pageMain';
import TrailerPageRu from "./pages/ru/trailer/TrailerPage";
import LocationPageRu from "./pages/ru/location/LocationPage";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getSitetsIds} from "./store/sites/actions";
import {setTrailers} from "./store/trailers/actions";
import {setLocations} from "./store/locations/actions";
import {setDefaultPage} from "./store/main-info/actions";
import {setLanguageEn, setLanguageHe, setLanguageRu} from "./store/language/actions";
import {configLangEn, configLangHe, configLangRu} from "./helper/change-language";
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga';
import DocumentsPage from "./pages/ru/documents/DocumentsPage";
import Agreement from "./pages/ru/agreement/Agreement";
import NewsPage from "./pages/ru/news/NewsPage";
import "bootstrap/dist/css/bootstrap.min.css";
import './App.scss';
import './Normalize.css';
import './styles/variables.css';
import './styles/mixins.scss';


function App() {
  const tgm = useSelector((state) => state.main.default_info.gtmId);

  const TRACKING_ID = 'G-K8HE0BB6J8';

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
  }, [])
  useEffect(() => {
    TagManager.initialize({gtmId: tgm});

  }, [tgm]);

  const dispatch = useDispatch();
  const params = useParams()

  const sitesId = useSelector((state) => state.sites.sites);


  const language = useSelector((state) => state.language.params);
  const local = localStorage.getItem('isChange');


  useEffect(() => {


    if (local === "he" || local === null) {

      configLangHe()
      dispatch(setLanguageHe());


    } else if (local === "ru") {
      localStorage.setItem('isChange', 'ru')
      dispatch(setLanguageRu());
      configLangRu()

    } else if (local === "en") {
      localStorage.setItem('isChange', 'en')
      configLangEn()
      dispatch(setLanguageEn())
    }


  }, [dispatch, local]);

  useEffect(() => {


    if (params.lang === "he") {
      
      configLangHe()
      localStorage.setItem('isChange', 'he')

    } else if (params.lang === null) {
      localStorage.setItem('isChange', 'he')
    
    } else if (params.lang === "ru") {
      localStorage.setItem('isChange', 'ru')
     
      configLangRu()

    } else if (params.lang === "en") {
      localStorage.setItem('isChange', 'en')
      configLangEn()
    }

  },);
  useEffect(() => {

    if (params.lang === 'he') {
      dispatch(setLanguageHe());
      configLangHe()


    } else if (params.lang === null) {

      dispatch(setLanguageHe());
      configLangHe()


    } else if (params.lang === "ru") {
      configLangRu()

      dispatch(setLanguageRu());

    } else if (params.lang === "en") {
      configLangEn()
      dispatch(setLanguageEn());

    }
    dispatch(getSitetsIds(language));
    dispatch(setTrailers(language));
    dispatch(setLocations(language));
    if (sitesId ?. id) {
      dispatch(setDefaultPage(sitesId ?. id, language));
    }
  }, [
    sitesId ?. id,
    language,
    params.lang,
    dispatch
  ]);

  return (
    <div className="App  ">

      <Routes>
        <Route path={"/"}
          element={<PageMainRu/>}/>
        <Route path={"/:lang"}
          element={<PageMainRu/>}/>

        <Route path="/location"
          element={<LocationPageRu/>}/> 
        <Route path="/locations/:location/:lang/:slug"
          element={<LocationPageRu/>}/> 
        <Route path="/trailers/:trailers/:lang/:slug"
          element={<TrailerPageRu/>}/>
        <Route path="/documents/:docs/:lang/"
          element={<DocumentsPage/>}/>
        <Route path="/agreement/:article/:lang/"
          element={<Agreement/>}/>
        <Route path="/news/:all/:lang/"
          element={<NewsPage/>}/>
      </Routes>
    </div>
  );
}

export default App;
