
import { getSites } from "../../API/APIService";

export const GET_SITES = 'trailers/GET_SITES';

export function getSitetsIds(params) {
  return async dispatch => {
    try {
      const response = await getSites(params);
      const data = await response.data;
      dispatch({type: GET_SITES, data: data})
    } catch (e) {}
  }
}
