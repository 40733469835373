import {getAgreement} from "../../API/APIService";

export const SET_AGREEMENT_PAGE = 'agreement/SET_AGREEMENT_PAGE';

export function setAgreement(params) {
  return async dispatch => {
    try {
      const response = await getAgreement(params);
      const data = await response.data;
      dispatch({type: SET_AGREEMENT_PAGE, data: data})

    } catch (e) {}
  }
}
