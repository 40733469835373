import {setCurrentLanguage, setLanguageEn, setLanguageHe, setLanguageRu} from "../store/language/actions";

export function changeLanguage(e, dispatch, setIsChange, navigate) {
  let lang_attr = e.target;

  let current_lang = document.querySelector(".languages__link_active");
  current_lang.setAttribute("data-lang", lang_attr.getAttribute("data-lang"));
  current_lang.textContent = e.target.textContent;

  if (e.target.getAttribute("data-lang") === "he") {

    document.querySelector(".en").classList.remove("hidden");
    document.querySelector(".ru").classList.remove("hidden");
    document.querySelector(".languages__box.he").classList.add("hidden");
    document.querySelector(".App").classList.add("he");
    document.querySelector(".language__lists").classList.toggle("active");
    dispatch(setLanguageHe());

    setIsChange(true);
    // localStorage.setItem("isChange", e.target.getAttribute("data-lang"));
    dispatch(setCurrentLanguage('he'))
    // navigate('/')
    return;
  }
  if (e.target.getAttribute("data-lang") === "ru") { // console.log('..')
    document.querySelector(".en").classList.remove("hidden");
    document.querySelector(".ru").classList.add("hidden");
    document.querySelector(".languages__box.he").classList.remove("hidden");
    document.querySelector(".language__lists").classList.toggle("active");
    document.querySelector(".App").classList.remove("he");
    dispatch(setLanguageRu());
    setIsChange(false);
    dispatch(setCurrentLanguage('ru'))
    // localStorage.setItem("isChange", e.target.getAttribute("data-lang"));


    // navigate('/ru')
    return;
  }
  if (e.target.getAttribute("data-lang") === "en") {
    document.querySelector(".en").classList.add("hidden");
    document.querySelector(".ru").classList.remove("hidden");
    document.querySelector(".languages__box.he").classList.remove("hidden");

    document.querySelector(".language__lists").classList.toggle("active");
    document.querySelector(".App").classList.remove("he");
    dispatch(setLanguageEn());
    setIsChange(false);
    dispatch(setCurrentLanguage('en'))

    // localStorage.setItem("isChange", e.target.getAttribute("data-lang"));
    // navigate('/en')
    return;
  }
}


export function configLangHe() {
  let current_lang = document.querySelector(".languages__link_active");
  
  document.querySelector(".App").classList.add("he");
  current_lang.setAttribute("data-lang", "he");
  current_lang.textContent = "he";
  // document.querySelector(".App").classList.add("he");
  document.querySelector(".en").classList.remove("hidden");
  document.querySelector(".ru").classList.remove("hidden");
  document.querySelector(".languages__box.he").classList.add("hidden");
  document.querySelectorAll('button').forEach(e => {
    e.setAttribute('dir', 'rtl');
  })
  // document.querySelector('.accordion-button').setAttribute('dir', 'rtl');
  document.querySelectorAll('p').forEach(e => {
    e.setAttribute('dir', 'rtl');
  })
  document.querySelectorAll('.languages__link').forEach(e => {
    e.setAttribute('dir', 'ltr');
  })
}
export function configLangRu() {
  let current_lang = document.querySelector(".languages__link_active");
  current_lang.setAttribute("data-lang", "ru");
  current_lang.textContent = "ru";
  document.querySelector(".App").classList.remove("he");
  document.querySelector(".en").classList.remove("hidden");
  document.querySelector(".ru").classList.add("hidden");
  document.querySelector(".languages__box.he").classList.remove("hidden");
  document.querySelectorAll('button').forEach(e => {
    e.setAttribute('dir', 'ltr');
  })
  document.querySelectorAll('p').forEach(e => {
    e.setAttribute('dir', 'ltr');
  })
  document.querySelectorAll('.languages__link').forEach(e => {
    e.setAttribute('dir', 'ltr');
  })
}
export function configLangEn() {
  let current_lang = document.querySelector(".languages__link_active");
  current_lang.setAttribute("data-lang", "en");
  current_lang.textContent = "en";
  document.querySelector(".App").classList.remove("he");
  document.querySelector(".en").classList.add("hidden");
  document.querySelector(".ru").classList.remove("hidden");
  document.querySelector(".languages__box.he").classList.remove("hidden");
  document.querySelectorAll('button').forEach(e => {
    e.setAttribute('dir', 'ltr');
  })
  document.querySelectorAll('p').forEach(e => {
    e.setAttribute('dir', 'ltr');
  })
  document.querySelectorAll('.languages__link').forEach(e => {
    e.setAttribute('dir', 'ltr');
  })
}
