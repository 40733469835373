import axios from 'axios';
import {useDispatch} from 'react-redux';
import {getStatus} from '../store/email/actions';


export function getSites(params) {
  return axios.get(`https://back.avalon.co.il:8001/api/v1/sites`, params)
}

export function getInfoPages(id, params) {
  return axios.get(`https://back.avalon.co.il:8001/api/v1/sites/${id}`, params)
}
export function getTrailers(params) {
  return axios.get(`https://back.avalon.co.il:8001/api/v1/trailers/`, params)
}
export function getTrailersPage(slug, params) {
  return axios.get(`https://back.avalon.co.il:8001/api/v1/trailers/${slug}`, params)
}


export function getLocations(params) {
  return axios.get(`https://back.avalon.co.il:8001/api/v1/campings/`, params)
}

export function getLocationPage(slug, params) {
  return axios.get(`https://back.avalon.co.il:8001/api/v1/campings/${slug}`, params)
}


export function getDocs(params) {
  return axios.get(`https://back.avalon.co.il:8001/api/v1/docs/`, params)
}



export function getAgreement(params) {
  return axios.get(`https://back.avalon.co.il:8001/api/v1/pagecontent/`, params)
}


export function getAllCategories(params) {
  return axios.get(`https://back.avalon.co.il:8001/api/v1/campings/categories/`, params)
}
export function submitForm(adress, phone, username, id) {

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },

    data: {
      "address": adress,
      "phone": phone,
      "username": username,
      "site": id
    },
    url: 'https://back.avalon.co.il:8001/api/v1/emails/create/'
  };
  axios(options)
  return axios(options)

}
