import {getInfoPages} from "../../API/APIService";

export const SET_MAIN_PAGE = 'trailers/SET_MAIN_PAGE';


export function setDefaultPage(id, params) {
  return async dispatch => {
    try {
      const response = await getInfoPages(id, params);
      const data = await response.data;
      dispatch({type: SET_MAIN_PAGE, data: data})
    } catch (e) {}
  }
}

