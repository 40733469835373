import React from "react";
import ButtonGreen from "../button/green/ButtonGreen";
import Title from "../title/Title";
import DOMParserReact from "dom-parser-react";
import "./Description.scss";

export default function Description({
	children,
	title,
	desc,
	url,
	buttonTitle,
}) {
	return (
		<div className="description">
			<Title title={title} />
			<div className="description__container">
				<p className="description__text desc">
				<DOMParserReact source= {desc}/></p>
				{/* <Link to={"https://app.b-on.co.il/online/order-v2/dream-caravans"}> */}
					<ButtonGreen
						url={url}
						className="description__button"
						title={buttonTitle}
					/>
				{/* </Link> */}
				<div className="desc__box">{children}</div>
			</div> 
		</div>
	);
}
