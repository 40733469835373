import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-scroll";
import ReactGA from "react-ga";
import ButtonTransparent from "../button/transparent/ButtonTransparent";
import DOMParserReact from "dom-parser-react";
import "./Footer.scss";

export default function Footer() {
	const state = useSelector((state) => state.main.footer);
	const default_info = useSelector((state) => state.main.default_info);
	const static_info = useSelector((state) => state.language.language);
	const trailers_img = useSelector((state) => state.trailers.all_trailers);

	return (
		<footer className="footer">
			<div className="footer__container">
				<div className="footer__box">
					<div className="footer__row">
						<div className="footer__info footer__text">
							{default_info?.logo && (
								<a
									href="/"
									aria-label={"logo"}
									className="header__logo"
									style={{
										display: "block",
										background: `left/cover no-repeat url(${default_info?.logo})`,
									}}>
									{}
								</a>
							)}
							{state?.text && (
								<p className="footer__desc">
									{" "}
									<DOMParserReact source={state?.text} />
								</p>
							)}
							<div className="footer__social">
								{default_info?.footer?.social_links?.length !== 0 &&
									default_info?.footer?.social_links?.map((item, index) => (
										<a
											key={index}
											href={item.url}
											className={`footer__link footer__link_${item.name.toLowerCase()}`}>
											{}
										</a>
									))}
							</div>
						</div>
						<div className="footer__info footer__imgs">
							{state?.label && <p className="footer__name">{state?.label}</p>}
							<Link className="footer__flex" href="#" to={"trailers"}>
								{trailers_img?.length !== 0 &&
									trailers_img
										.slice(0, 8)
										.map((item, index) => (
											<div
												key={index}
												className="footer__item"
												style={{ backgroundImage: `url(${item.main_image})` }}></div>
										))}
							</Link>
						</div>
					</div>
					<div className="footer__info footer__contacts">
						<div className="footer__boxes">
							{static_info?.contact_title && (
								<p className="footer__name">{static_info?.contact_title}</p>
							)}
							<a href={`tel:${default_info?.phone_number}`} className="contact__link">
								{default_info?.phone_number}
							</a>
							<div
								onClick={() => {
									ReactGA.event({
										category: "button",
										action: "click",
										label: "order now ",
										nonInteraction: false,
									});
								}}>
								{static_info?.button_consultation && (
									<ButtonTransparent
										className="footer__button"
										title={static_info?.button_consultation}
										url={"https://app.b-on.co.il/online/order-v2/dream-caravans"}
									/>
								)}
							</div>
						</div>
						<div className="mobile">
							{static_info?.contact_title && (
								<p className="footer__name">{static_info?.contact_title}</p>
							)}
							<div className="mobile__box">
								<div
									onClick={() => {
										ReactGA.event({
											category: "button",
											action: "click",
											label: "order now ",
											nonInteraction: false,
										});
									}}>
									<ButtonTransparent
										url={"https://app.b-on.co.il/online/order-v2/dream-caravans"}
										className="footer__button"
										title={static_info?.button_consultation}
									/>
								</div>
								{default_info?.phone_number && (
									<a
										href={`tel:${default_info?.phone_number}`}
										className="contact__link">
										{default_info?.phone_number}
									</a>
								)}
							</div>
						</div>
					</div>
					<div className="footer__info footer__imgs footer__imgs-mobile">
						<p className="footer__name">{state?.label}</p>
						<div className="footer__flex">
							{trailers_img?.length !== 0 &&
								trailers_img
									.slice(0, 8)
									.map((item, index) => (
										<div
											key={index}
											className="footer__item"
											style={{ backgroundImage: `url(${item?.main_image})` }}></div>
									))}
						</div>
					</div>
				</div>
				<div className="footer__copy">
					<div className="footer__copy-text">
						© Copyright 2023. All Rights Reserved.
					</div>
					<div className="footer__social">
						{default_info?.footer?.social_links?.length !==0 &&
							default_info?.footer?.social_links?.map((item, index) => (
								<a
									key={index}
									href={item.url}
									className={`footer__link footer__link_${item?.name}`}>
									{}
								</a>
							))}
					</div>
				</div>
			</div>
		</footer>
	);
}
