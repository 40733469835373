import React, { Suspense, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
	setLanguageEn,
	setLanguageHe,
	setLanguageRu,
} from "../../../store/language/actions";
import {
	configLangEn,
	configLangHe,
	configLangRu,
} from "../../../helper/change-language";
import Header from "../../../components/header/Header";
// import Preview from "../../../components/preview/Preview";
import About from "../../../components/about/About";
import HelmetConfig from "../../../components/helmet/HelmetConfig";
import { chartLimitation } from "../../../helper/characterLimitation";
import { footer } from "../footer";

const Reviews = React.lazy(() => import("../../../components/reviews/Reviews"));
const Preview = React.lazy(() => import("../../../components/preview/Preview"));

const Trailers = React.lazy(() =>
	import("../../../components/trailers/Trailers")
);
const OurHelp = React.lazy(() =>
	import("../../../components/ourHelps/OurHelp")
);
const Options = React.lazy(() => import("../../../components/options/Options"));
const Contacts = React.lazy(() =>
	import("../../../components/contacts/Contacts")
);

const Location = React.lazy(() =>
	import("../../../components/location/Location")
);
const Footer = React.lazy(() => import("../../../components/footer/Footer"));

const getLanguageFromLocalStorage = () => {
	const local = localStorage.getItem("isChange");
	return local || "he"; // По умолчанию, если значение не установлено, используем "he"
};

export default function PageMainRu() {
	const defaultInfo = useSelector((state) => state.main.default_info);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();

	const lang = params.lang || getLanguageFromLocalStorage();

	useEffect(() => {
		let languageChanged = false;

		if (lang === "he") {
			configLangHe();
			dispatch(setLanguageHe());
			languageChanged = true;
		} else if (lang === "ru") {
			configLangRu();
			dispatch(setLanguageRu());
			languageChanged = true;
		} else if (lang === "en") {
			configLangEn();
			dispatch(setLanguageEn());
			languageChanged = true;
		}

		// Обновляем localStorage только если произошло изменение языка
		if (languageChanged) {
			localStorage.setItem("isChange", lang);
		}

		// Если параметр lang не установлен, перенаправляем на текущий язык
		if (!params.lang) {
			navigate(`/${lang}`);
		}
	}, [dispatch, navigate, params.lang, lang]);

	return (
		<main>
			<HelmetConfig
				title={chartLimitation(defaultInfo?.logo_title)}
				desc={chartLimitation(defaultInfo?.logo_description)}
			/>
			<div className="page ">
				{/* <Сookies /> */}
				<Header image={""} />
				<Suspense fallback={<div>Loading...</div>}>
					<Preview />
				</Suspense>
				<About />

				<div className="page__container">
					<div className="page__wrapper">
						<Suspense fallback={<div>Loading...</div>}>
							<OurHelp />
						</Suspense>
						<Suspense fallback={<div>Loading...</div>}>
							<Trailers id="trailers" />
						</Suspense>
					</div>
				</div>

				<Suspense fallback={<div>Loading...</div>}>
					<Contacts id="contacts" />
				</Suspense>

				<div className="page__container">
					<div className="page__wrapper">
						<Suspense fallback={<div>Loading...</div>}>
							<Options id="options" />
						</Suspense>
						<Suspense fallback={<div>Loading...</div>}>
							<Location id="location" />
						</Suspense>
					</div>
				</div>

				<Suspense fallback={<div>Loading...</div>}>
					<Reviews />
				</Suspense>
				<Suspense fallback={<div>Loading...</div>}>
					<Footer footer={footer} />
				</Suspense>
			</div>
		</main>
	);
}
