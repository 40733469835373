import cloneDeep from "lodash.clonedeep";
import {SET_DOCS_PAGE} from "./actions";

const initialState = {
  docs: {}

}

export const docsPageReduser = (state = initialState, action) => {

  switch (action.type) {

    case SET_DOCS_PAGE:
      const clone = cloneDeep(state);
      clone.docs = action.data;
      return clone;

    default:
      return state
  }
}
