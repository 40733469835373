import {getDocs} from "../../API/APIService";

export const SET_DOCS_PAGE = 'docs/SET_DOCS_PAGE';

export function setDocs(params) {
  return async dispatch => {
    try {
      const response = await getDocs(params);
      const data = await response.data;
      dispatch({type: SET_DOCS_PAGE, data: data})

    } catch (e) {}
  }
}
