export const GET_DISABLED_IMAGE = 'disabled-config/GET_DISABLED_IMAGE';
export const GET_DISABLED_FONTS = 'disabled-config/GET_DISABLED_FONTS';
export const GET_DISABLED_BACKGROUND = 'disabled-config/GET_DISABLED_BACKGROUND';

export function getImg(img) {
  return {type: GET_DISABLED_IMAGE, data: img}
}

export function getFonts(font) {
  return {type: GET_DISABLED_FONTS, data: font}
}
export function getBackground(bg) {
  return {type: GET_DISABLED_BACKGROUND, data: bg}
}