export const SET_LANGUAGE_RU = 'language /SET_LANGUAGE_RU';
export const SET_LANGUAGE_HE = 'language /SET_LANGUAGE_HE';
export const SET_LANGUAGE_EN = 'language /SET_LANGUAGE_EN';
export const SET_CURRENT_LANGUAGE = 'language /SET_CURRENT_LANGUAGE';



export function setLanguageRu() {
  return {type: SET_LANGUAGE_RU}
}
export function setLanguageHe() {
  return {type: SET_LANGUAGE_HE}
}

export function setLanguageEn() {
  return {type: SET_LANGUAGE_EN}
}
export function setCurrentLanguage(lang) {
  return {type: SET_CURRENT_LANGUAGE,lang}
}
