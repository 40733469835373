import cloneDeep from "lodash.clonedeep";
import {params_he, params_ru, params_en} from "../../API/params";
import {infoHe} from "../../helper/staticInfoHe";
// import {info} from "../../helper/staticInfoHe";

import {infoRu} from "../../helper/staticInfoRu";
import {SET_LANGUAGE_RU, SET_LANGUAGE_HE, SET_LANGUAGE_EN, SET_CURRENT_LANGUAGE} from "./actions";
import {infoEn} from "../../helper/staticInfoEn";

const initialState = {
  language: infoHe,
  params: params_he,
  current_lang: localStorage.getItem('isChange') ||''
}

export const languageRedusers = (state = initialState, action) => {

  switch (action.type) {

    case SET_LANGUAGE_RU:
      {

        const clone = cloneDeep(state);
        clone.language = infoRu;
        clone.params = params_ru;

        return clone;
      }
    case SET_LANGUAGE_HE:
      {
        const clone = cloneDeep(state);
        clone.language = infoHe;
        clone.params = params_he;
        return clone;
      }
    case SET_LANGUAGE_EN:
      {
        const clone = cloneDeep(state);
        clone.language = infoEn;
        clone.params = params_en;
        return clone;
      }

    case SET_CURRENT_LANGUAGE:
      {
        const clone = cloneDeep(state);
        // clone.current_lang = action.lang;
        return clone;
      }
    default:
      return state
  }
}
