
import {getTrailersPage} from "../../API/APIService";

export const SET_TRAILER_PAGE = 'trailers-page/SET_TRAILERS';

export function setTrailersPage(slug, params) {
  return async dispatch => {
    try {
      const response = await getTrailersPage(slug, params);
      const data = await response.data;
      dispatch({type: SET_TRAILER_PAGE, data: data})
    } catch (e) {}
  }
}
