export const infoHe = {

  // buttons
  button_show: "קרא עוד",
  button_consultation: "הזמנת קרוואן",
  button_more: ` הזמן עכשיו`,
  button_contact: 'הזמן עכשיו ',
  news_bnt:"כל הכתבות",
  news:{
    breadcrumbs_a:'ראש',
    breadcrumbs_b:'בלוג',
    all:"כל"

  },

  aside:{
    social_title:"הישאר מעודכן לגבי קידום המכירות והחדשות שלנו",
    social_desc:"לחתום",
    hit:"להיט",
    hit_desc:'טריילר מודרני',
    blog:"מאמרים אחרונים",
    
  },
  // titles

  blog_title:"בלוג",
  contact_title: `!צור קשר`,
  // units
  price:'מחיר',
  height: 'גוֹבַה',
  height_units: 'מ',
  length: 'אורך',
  length_units: 'מ',
  weight: 'המשקל',
  weight_units: 'ט',
  sleeping_places: "מקומות שינה",
  square: "אזור יעיל",
  square_units: 'מ',
  // popup
  popup_title:"הרשמו וקבלו ייעוץ בנושא העניין",
  popup_name:"השם שלך",
  popup_email:"אימייל",
  popup_phone:"טלפון",

  
  title_location:'לאן אפשר ללכת עם נגרר',
  disabled: {
    font:"גודל גופן Px",
    bg:"צבע האתר",
    img:"תמונות",
    reset:"אִתחוּל"
  },
  cookies: {
    text: `אנו משתמשים בעוגיות כדי לספק לך נוחות מרבית
    האתר שלנו.`,
    button: "OK"
  }
}
