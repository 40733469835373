import cloneDeep from "lodash.clonedeep";
import {GET_SITES} from "./actions";

const initialState = {
  sites: {}

}

export const sitesReduser = (state = initialState, action) => {

  switch (action.type) {

    case GET_SITES:
      const clone = cloneDeep(state);
      action.data.map(item => {
        return clone.sites = item;
      })
      return clone;

    default:
      return state
  }
}
