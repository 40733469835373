import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import {
	setLanguageEn,
	setLanguageHe,
	setLanguageRu,
} from "../../store/language/actions";
import DOMParserReact from "dom-parser-react";
import ReactGA from "react-ga";
import "./LoactionCard.scss";



export default function LoactionCard({ location }) {
	const [slug, setSlug] = useState("");

	const dispatch = useDispatch();
	const params = useParams();

	const language = useSelector((state) => state.language.language);
	const language_params = useSelector((state) => state.language.params);

	useEffect(() => {
		if (params.lang === "he") {
			setSlug("he");
			localStorage.setItem("isChange", "he");
			dispatch(setLanguageHe());
		}
		if (params.lang === "ru") {
			localStorage.setItem("isChange", "ru");
			setSlug("ru");
			dispatch(setLanguageRu());
		}
		if (params.lang === "en") {
			localStorage.setItem("isChange", "en");
			setSlug("en");
			dispatch(setLanguageEn());
		}
	}, [dispatch, language_params, params.lang]);
	return (
		<div className="location-card">
			<div className="location-card__container">
				<div
					className="location-card__img "
					style={{ backgroundImage: `url(${location?.main_image})` }}>
					<p className="location-card__name">
						<DOMParserReact source={location?.category_name} />
					</p>
				</div>
				<div className="location-card__info">
					<p className="location-card__title">{location?.subname}</p>
					<div className="location-card__desc">
						<DOMParserReact source={location?.short_description} />
					</div>
					<a
						href={`/locations/location-card/${localStorage.getItem("isChange")}/${
							location?.slug
						}`}
						className="location-card__link"
						// target="_blank"
						rel="noreferrer"
						onClick={() => {
							ReactGA.event({
								category: "button",
								action: "click",
								label: "order now ",
								nonInteraction: false,
							});
						}}>
						{language?.button_show}
					</a>
				</div>
			</div>
		</div>
	);
}
